<script setup lang="ts">

</script>

<template>
    <div class="main-title-wrap">
        <div class="main-title">
            <img src="@/assets/images/icon.png" />
            <span>橙功宠物</span>
        </div>
    </div>
</template>

<style lang="less">
.main-title-wrap {
    .main-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px 0;
        img {
            width: 60px;
            height: 60px;
            margin-right: 14px;
        }
        span {
            font-size: 48px;
            color: #362C2C;
            font-weight: 600;
        }
    }
}
</style>
