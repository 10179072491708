const INTER = [
    {
        space_src: 'baidujs',
        space_type: 'inters',
        space_id: 'u6965426',
        space_js: '//h5fpb1.szcgsoft.cn/source/q/common/lk/tki-hak.js'
    }];
const BANNER = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6965427',
        space_js: '//h5fpb1.szcgsoft.cn/site/r/openjs/m/lulj/production/ib/static/n.js'
    }
];
const NATIVE_1 = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6965428',
        space_js: '//h5fpb1.szcgsoft.cn/site/sn-m/static/vm/production/k-jcp.js'
    }
];
const NATIVE_2 = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6965429',
        space_js: '//h5fpb1.szcgsoft.cn/source/t-on/w/static/nlkdw.js'
    }
];
const NATIVE_3 = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6965430',
        space_js: '//h5fpb1.szcgsoft.cn/site/l/static/g_fofdc/production/y/source/m.js'
    }
];


/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_banner: BANNER,
    Main_native: NATIVE_1,
    Classroom_native: NATIVE_2,
    Wikipedia_cat_native: NATIVE_1,
    Wikipedia_dog_native: NATIVE_2,
    Wikipedia_other_native: NATIVE_3,
    ConstellationList_banner: BANNER,
    ConstellationList_native: NATIVE_1,
    ArticleDetail_inter: INTER,
    ArticleDetail_banner: BANNER,
    ArticleDetail_native: NATIVE_1,
    PetDetail_inter: INTER,
    PetDetail_banner: BANNER,
    PetDetail_native: NATIVE_1,
    Mood_dialog: NATIVE_3,
};
